<template>
  <div>
    <base-page-heading title="Editare produs solicitat" v-bind:subtitle="futureOrder ? futureOrder.product : '-'">
      <template #extra>
        <b-breadcrumb class="breadcrumb-alt">
          <b-breadcrumb-item href="javascript:void(0)">Produsele solicitate</b-breadcrumb-item>
          <b-breadcrumb-item active>Editarea solicitarii {{futureOrder ? futureOrder.product : '-'}}</b-breadcrumb-item>
        </b-breadcrumb>
      </template>
    </base-page-heading>
    <div class="content">
      <b-row class="row-deck">
        <b-col xl="12">
          <base-block>
            <b-row class="row-deck">
              <b-col md="4" class="col-12">
                <b-button size="md" type="button" variant="primary" class="mb-3" @click="backToList">
                  <small><i class="fa fa-fw fa-arrow-left"></i> Inapoi la lista de solicitari</small>
                </b-button>
              </b-col>
            </b-row>
            <hr/>
            <b-form @submit.stop.prevent="onAddFutureOrderSubmit">
              <b-row>
                <b-col xl="12">
                  <base-block title="Introduce datele solicitarii pentru editare" header-bg content-full>
                    <b-row>
                      <b-col xl="4" offset-xl="2">
                        <b-form-group label-for="category">
                          <b-form-select id="category" size="lg" class="form-control-alt" :options="categoryChoices" plain name="category" v-model="$v.editFutureOrderForm.category.$model" :state="!$v.editFutureOrderForm.category.$error && (!errors.editFutureOrderForm.category) && null" aria-describedby="category-feedback" @blur="removeServerErrors('editFutureOrderForm')" @input="removeServerErrors('editFutureOrderForm')"></b-form-select>
                          <b-form-invalid-feedback v-if="!$v.editFutureOrderForm.category.required" id="category-feedback">
                            Campul este obligatoriu!
                          </b-form-invalid-feedback>
                          <b-form-invalid-feedback v-if="errors.editFutureOrderForm.category" v-text="errors.editFutureOrderForm.category" id="product-feedback"></b-form-invalid-feedback>
                        </b-form-group>
                        <div class="form-group">
                          <b-form-input type="text" size="lg" class="form-control-alt" id="product" name="product" placeholder="Produs" v-model="$v.editFutureOrderForm.product.$model" :state="!$v.editFutureOrderForm.product.$error && (!errors.editFutureOrderForm.product) && null" aria-describedby="product-feedback" @blur="removeServerErrors('editFutureOrderForm')" @input="removeServerErrors('editFutureOrderForm')"></b-form-input>
                          <b-form-invalid-feedback v-if="!$v.editFutureOrderForm.product.required" id="product-feedback">
                            Campul este obligatoriu!
                          </b-form-invalid-feedback>
                          <b-form-invalid-feedback v-if="!$v.editFutureOrderForm.product.minLength" id="product-feedback">
                            Campul trebuie sa aiba minimum 3 caractere!
                          </b-form-invalid-feedback>
                          <b-form-invalid-feedback v-if="errors.editFutureOrderForm.product" v-text="errors.editFutureOrderForm.product" id="product-feedback"></b-form-invalid-feedback>
                        </div>
                        <b-form-group label-for="type">
                          <b-form-select id="type" size="lg" class="form-control-alt" :options="typeChoices" plain name="type" v-model="$v.editFutureOrderForm.type.$model" :state="!$v.editFutureOrderForm.type.$error && (!errors.editFutureOrderForm.type) && null" aria-describedby="type-feedback" @blur="removeServerErrors('editFutureOrderForm')" @input="removeServerErrors('editFutureOrderForm')"></b-form-select>
                          <b-form-invalid-feedback v-if="!$v.editFutureOrderForm.type.required" id="type-feedback">
                            Campul este obligatoriu!
                          </b-form-invalid-feedback>
                          <b-form-invalid-feedback v-if="errors.editFutureOrderForm.type" v-text="errors.editFutureOrderForm.type" id="type-feedback"></b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group label-for="category">
                          <b-form-select id="status" size="lg" class="form-control-alt" :options="statusChoices" plain name="status" v-model="$v.editFutureOrderForm.status.$model" :state="!$v.editFutureOrderForm.status.$error && (!errors.editFutureOrderForm.status) && null" aria-describedby="category-feedback" @blur="removeServerErrors('editFutureOrderForm')" @input="removeServerErrors('editFutureOrderForm')"></b-form-select>
                          <b-form-invalid-feedback v-if="!$v.editFutureOrderForm.status.required" id="status-feedback">
                            Campul este obligatoriu!
                          </b-form-invalid-feedback>
                          <b-form-invalid-feedback v-if="errors.editFutureOrderForm.status" v-text="errors.editFutureOrderForm.status" id="status-feedback"></b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col xl="4">
                        <b-form-group label-for="observations">
                          <b-textarea rows="3" name="observations" id="observations" class="form-control-alt border" placeholder="Introduceti mentiuni aici..." v-model="$v.editFutureOrderForm.observations.$model" :state="!$v.editFutureOrderForm.observations.$error && (!errors.editFutureOrderForm.observations) && null" aria-describedby="observations-feedback" @blur="removeServerErrors('editFutureOrderForm')" @input="removeServerErrors('editFutureOrderForm')"></b-textarea>
                          <b-form-invalid-feedback v-if="!$v.editFutureOrderForm.observations.required" id="observations-feedback">
                            Campul este obligatoriu!
                          </b-form-invalid-feedback>
                          <b-form-invalid-feedback v-if="errors.editFutureOrderForm.observations" v-text="errors.editFutureOrderForm.observations" id="observations-feedback"></b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group label-for="term">
                          <flat-pickr size="lg" id="term" class="form-control form-control-alt" placeholder="Termen de livrare" v-model="$v.editFutureOrderForm.term.$model" :config="datepickerConfig"></flat-pickr>
                        </b-form-group>
                        <div>
                          <b-row>
                            <b-col xl="4" offset-xl="8">
                              <b-button type="submit" variant="primary" block>
                                <i class="fa fa-fw fa-pencil-alt mr-1"></i> Editare
                              </b-button>
                            </b-col>
                          </b-row>
                        </div>
                      </b-col>
                    </b-row>
                  </base-block>
                </b-col>
              </b-row>
            </b-form>
          </base-block>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<style lang="scss">
// Flatpickr + Custom overrides
@import '~flatpickr/dist/flatpickr.css';
@import './src/assets/scss/vendor/flatpickr';
</style>

<script>
import {validationMixin} from "vuelidate";
import {minLength, required} from "vuelidate/lib/validators";
import flatPickr from "vue-flatpickr-component";
import {EDIT_FUTURE_ORDER, GET_DATA_FOR_EDIT} from "../../../constants/future-order-graphql";
import {fleshErrorMessage, handleFormValidation} from "../../../error/server-error";

export default {
  name: "EditFutureOrder",
  mixins: [validationMixin],
  components: {flatPickr},
  data () {
    return {
      editId: atob(this.$route.params.id),
      futureOrder: null,
      datepickerConfig: {},
      typeChoices: [],
      statusChoices: [],
      categoryChoices: [],
      editFutureOrderForm: {
        category: null,
        product: null,
        type: null,
        observations: null,
        term: null,
        status: null,
      },
      errors: {
        editFutureOrderForm: {},
      },
    }
  },
  validations: {
    editFutureOrderForm: {
      category: {
        required
      },
      product: {
        required,
        minLength: minLength(3)
      },
      type: {
        required
      },
      observations: {
      },
      term: {
      },
      status: {
        required
      }
    }
  },
  methods: {
    onAddFutureOrderSubmit() {
      this.errors.editFutureOrderForm = {};
      this.$v.editFutureOrderForm.$touch()
      if (this.$v.editFutureOrderForm.$anyError) {
        return;
      }
      this.$apollo.mutate({
        mutation: EDIT_FUTURE_ORDER,
        variables: {
          'futureOrderId': this.futureOrder._id,
          'categoryId': this.$v.editFutureOrderForm.category.$model,
          'product': this.$v.editFutureOrderForm.product.$model,
          'type': this.$v.editFutureOrderForm.type.$model,
          'status': this.$v.editFutureOrderForm.status.$model,
          'comment': this.$v.editFutureOrderForm.observations.$model && this.$v.editFutureOrderForm.observations.$model !== "" ? this.$v.editFutureOrderForm.observations.$model : null,
          'term': this.$v.editFutureOrderForm.term.$model && this.$v.editFutureOrderForm.term.$model !== "" ? this.$v.editFutureOrderForm.term.$model : null,
        }
      }).then(() => {
        this.flashMessage.info({message: "Solicitare editat cu succes!"});
        this.$router
            .push({name: "futureOrderList", params: {}, query: this.$route.query})
            .catch(error => {
              error = {};
              fleshErrorMessage(error, this);
            });
      }).catch((error) => {
        let form = this.$v.addUserForm;
        this.errors.addUserForm = handleFormValidation(error, form);
        fleshErrorMessage(error, this);
      })
    },
    removeServerErrors(form) {
      this.errors[form] = {};
    },
    backToList() {
      this.hasHistory() ?
          this.$router.go(-1) :
          this.$router.push('/requested-missing-product/list')
      ;
    },
    hasHistory() {
      return window.history.length > 2
    },
  },
  apollo: {
    meUser: {
      query: GET_DATA_FOR_EDIT,
      fetchPolicy: "no-cache",
      variables () {
        return {
          id: this.editId
        }
      },
      result(result) {
        this.statusChoices = result.data.meUser.futureOrderData.statusChoices;
        this.typeChoices = result.data.meUser.futureOrderData.typeChoices;
        this.futureOrder = result.data.futureOrder;
        let mainCategories = [];
        mainCategories.push({value: null, text: 'Selecteaza categorie'});
        result.data.productMainCategories.forEach(function (item) {
          let tmp = {};
          tmp.label = item.name;
          tmp.options = [];
          item.subCategories.forEach(function (subCategory) {
            tmp.options.push({value: subCategory._id, text: subCategory.name})
          });
          mainCategories.push(tmp);
        });
        this.categoryChoices = mainCategories;
        this.editFutureOrderForm.category = this.futureOrder.category._id;
        this.editFutureOrderForm.product = this.futureOrder.product;
        this.editFutureOrderForm.type = this.futureOrder.type;
        this.editFutureOrderForm.status = this.futureOrder.status;
        this.editFutureOrderForm.observations = this.futureOrder.comment;
        this.editFutureOrderForm.term = this.futureOrder.orderUntil;
        this.loaded = true;
      }
    }
  },
}
</script>